import React from "react"

import BrainIcon from "../svg/brain.svg";
import EqualIcon from "../svg/equal.svg";
import HandIcon from "../svg/hand.svg"

const icons = {
  "brain": BrainIcon,
  "equal": EqualIcon,
  "hand": HandIcon,
}

const Item = ({iconName, label}) => {
  const Icon = icons[iconName] || "div"
  return (
    <div className="flex flex-col items-center mt-6">
      <Icon className="w-12 h-12 block fill-current text-black mb-2" />
      <p className="text-blue-700 text-sm lg:text-lg font-bold">{label}</p>
    </div>
  )
}

function OurValuesSection() {
  return (
    <div className="mt-12 lg:mt-24 bg-gray-100">
      <div className="container pt-6 pb-8 lg:pt-8 lg:pb-10">
        <h3 className="text-xl md:text-2xl lg:text-3.5xl font-bold leading-tight mb-2 md:mb-4 text-gray-900 text-center lg:text-left">
          Our values
        </h3>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <Item
            iconName="hand"
            label="We stick to what we say"
          />
          <Item
            iconName="equal"
            label="Clients = Friends"
          />
          <Item
            iconName="brain"
            label="We have the Will ...to Make Things Happen"
          />
        </div>
      </div>
    </div>
  );
}

export default OurValuesSection;
