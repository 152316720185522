import React from 'react'
import classnames from 'classnames';

/**
 * 
 * @param {{ to: string, title: string, size: 'default' | 'large', external: boolean }} param0 
 */
function PrimaryButton({ to, title, disabled, size = 'default', ...props }) {

  const className = classnames(
    "inline-block font-bold text-xl leading-8  hover:text-white hover:bg-black focus:bg-black focus:outline-none",
    {
      "px-5 py-2": size === 'default',
      "px-6 py-3": size === 'large',
      "bg-primary-dark text-gray-200": !disabled,
      "bg-gray-800 text-gray-200 pointer-events-none": disabled,
    },
    props.className
  );

  return (
    <button className={className} disabled={disabled} {...props} />
  );
}

export default PrimaryButton
