import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import CloudIcon from "../svg/service-cloud.svg"
import MarketingIcon from "../svg/service-marketing.svg"
import MobileIcon from "../svg/service-mobile.svg"
import WebIcon from "../svg/service-web.svg"
import SectionTitle from "./SectionTitle"

const icons = {
  "wep-applications": WebIcon,
  "cloud-computing": CloudIcon,
  "mobile-applications": MobileIcon,
  "digital-marketing": MarketingIcon,
}

function ServiceSection() {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            id
            link
            title
            tagline
            description
          }
        }
      }
    }
  `)

  return (
    <div className="py-16 lg:pt-24 lg:pb-20 bg-white">
      <div className="container">
        <SectionTitle
          title="Services and expertise"
          excerpt={
            <>
              {" "}
              NMC Soft is a software development company with a
              proven expertise in <span className="text-blue-700">
                web
              </span> and{" "}
              <span className="text-blue-700">mobile applications</span>,{" "}
              <span className="text-blue-700">cloud computing</span>,{" "}
              <span className="text-blue-700">ecommerce applications</span> and
              more.
            </>
          }
        />
        <div className="pt-6 pb-4 grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5">
          {data.allServicesJson.edges.map(({ node: service }) => {
            const Icon = icons[service.id] || "div"
            return (
              <Link
                to={service.link}
                key={service.id}
                className="block bg-black hover:bg-primary-dark transition duration-200 ease-in text-white p-5 lg:p-6"
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Icon className="w-9 h-9 lg:w-16 lg:h-16 fill-current" />
                  </div>
                  <div className="pl-4 lg:pl-5 pt-1">
                    <span className="block text-lg lg:text-2xl font-bold leading-6">
                      {service.title}
                    </span>
                    <span className="block text-xxs lg:text-sm text-gray-100 pt-1">
                      {service.tagline}
                    </span>
                  </div>
                </div>
                <div className="pt-5">
                  <p className="text-base leading-5 lg:leading-6 font-normal">
                    {service.description}
                  </p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ServiceSection
