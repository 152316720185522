import React from "react"
import classnames from "classnames"
import { useField } from "formik"

function Input({ label, className, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div className={classnames("mb-2", className)}>
      <label className="block">
        {label && <span className="block text-gray-800">{label}</span>}
        <input
          {...field}
          {...props}
          className={classnames("block w-full", props.inputClassName, {
            "border-red-600": meta.touched && meta.error,
          })}
        />
      </label>
      {meta.touched && meta.error ? (
        <span className="text-xs text-red-600 font-medium leading-none">
          {meta.error}
        </span>
      ) : (
        <span className="text-xs font-medium leading-none">&nbsp;</span>
      )}
    </div>
  )
}

export default Input
