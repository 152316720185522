import React from "react"
import SectionTitle from "./SectionTitle"
import { Formik, Form } from "formik"

import * as Yup from "yup"
import Input from "./Form/Input"
import TextArea from "./Form/TextArea"
import * as notification from "./Form/notification"
import PrimaryButton from "./PrimaryButton"

import { sendWorkWithUsEmail } from "../emails/emails"

const WorkWithUsSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  email: Yup.string().email().required().label("Email"),
  company: Yup.object({
    name: Yup.string().label("Company Name"),
    website: Yup.string().label("Company Website"),
  }),
  message: Yup.string().required().label("Message"),
})

function WorkWithUs() {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <div className="relative py-8 md:py-12 bg-gray-100">
      <div id="work-with-us" className="container relative z-10">
        <SectionTitle
          title="Work with us"
          excerpt={
            <>
              There’s always a seat at the table for your creative idea.
              Complete the form below, or get in touch at{" "}
              <a
                className="text-blue-700 font-bold"
                href="mailto:online@newmedia.al"
              >
                online@newmedia.al
              </a>
            </>
          }
        />
      </div>
      <div className="container">
        <Formik
          initialValues={{
            name: "",
            email: "",
            company: {
              name: "",
              website: "",
            },
            message: "",
          }}
          validationSchema={WorkWithUsSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              setIsLoading(true)
              const { data } = await sendWorkWithUsEmail(values)
              if (data.message) {
                notification.success(data.message)
              }
              setIsLoading(false)
              resetForm()
            } catch (error) {
              setIsLoading(false)
              notification.error("Something went wrong. Try again!")
            }
          }}
        >
          {formikProps => {
            return (
              <Form className="max-w-4xl pt-8 grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <Input
                  name="name"
                  type="text"
                  label="Your Name"
                  placeholder="John Doe"
                />
                <Input
                  name="email"
                  type="email"
                  label="Your Email"
                  placeholder="johndoe@example.com"
                />
                <Input type="text" name="company.name" label="Company Name" />
                <Input
                  type="text"
                  name="company.website"
                  label="Company Website"
                />
                <TextArea
                  name="message"
                  label="Your Message"
                  placeholder="Hi..."
                  className="md:col-span-2"
                />
                <div>
                  <PrimaryButton disabled={isLoading} type="submit">
                    {isLoading ? "Loading..." : "Submit"}
                  </PrimaryButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default WorkWithUs
