import React from "react"

import Seo from "../components/seo"
import CompanyHero from "../components/CompanyHero"
import OurStorySection from "../components/OurStorySection"
import OurTeamSection from "../components/OurTeamSection"
import OurValuesSection from "../components/OurValuesSection"
import ServiceSection from "../components/ServiceSection"
import WorkWithUs from "../components/WorkWithUs"

const CompanyPage = () => (
  <>
    <Seo
      title="Company"
      description="Is a software development company with a proven expertise in web, mobile applications, cloud computing and ecommerce applications"
    />
    <CompanyHero />
    <OurStorySection />
    <OurTeamSection />
    <OurValuesSection />
    <ServiceSection />
    <WorkWithUs />
    <div className="h-12"></div>
  </>
)

export default CompanyPage
