import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function OurStorySection() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/our-story.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "our-story.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="pt-16 lg:pt-24 flex flex-col">
      <div className="flex flex-col lg:hidden">
        <h3 className="container text-4xl md:text-5xl font-bold leading-tight text-gray-900">
          Our Story
        </h3>
        <div className="w-full lg:hidden mt-5 mb-8">
          <GatsbyImage
            sizes={{
              ...data.mobileBackground.childImageSharp.gatsbyImageData,
              aspectRatio: 1.875 / 1,
            }}
          />
        </div>
      </div>
      <div className="flex container">
        <div className="flex flex-col justify-center lg:w-1/2 lg:pr-24">
          <h3 className="hidden lg:block text-4xl md:text-5xl font-bold leading-tight mb-8">
            Our Story
          </h3>
          <div className="mb-6 lg:mb-4">
            <p className="text-lg lg:text-2xl text-gray-700 font-semibold mb-3 lg:mb-2">
              We started on october 2007
            </p>
            <span className="text-base text-gray-800">
              As a marketing services company in Tirana registered as a small
              business.
              The 2 owners had a long experience in marketing &#38;
              advertising (creative &#38; media) as individuals, after that they
              decided to get together and invest on creating the agency.
            </span>
          </div>
          <div className="mb-6 lg:mb-4">
            <p className="text-lg lg:text-2xl text-gray-700 font-semibold mb-3 lg:mb-2">
              On february 2011
            </p>
            <span className="text-base text-gray-800">
              Following the new trends in marketing – the expansion of Digital -
              and by widening our focus of work we changed our name and our
              legal status in a New Company named{" "}
              <span className="font-bold text-blue-800">
                NMC Soft
              </span>
              .
            </span>
          </div>
          <div className="">
            <p className="text-lg lg:text-2xl text-gray-700 font-semibold mb-3 lg:mb-2">
              now...
            </p>
            <span className="text-base text-gray-800">
              NMC Soft stands for a new way of working with Technology
              Solutions &#38; Digital Marketing.
              <span className="py-4 block">
                Over the years, we've developed Innovative Digital Platforms
                &#38; powerful marketing solutions in the fields of Retail,
                Construction, Consumer goods, Financial, Manufacturing,
                Pharmaceutical, Real Estate, Education, Recreation, tourism
                &#38; travel etc.
              </span>
              Now we are present in Albania, Italy and USA.
            </span>
          </div>
        </div>
        <div className="hidden lg:block lg:w-1/2">
          <GatsbyImage
            image={data.desktopBackground.childImageSharp.gatsbyImageData}
            className="w-auto h-full"
            style={{ maxWidth: 640, marginLeft: "auto" }}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default OurStorySection
