import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import PrimaryLinkButton from "./PrimaryLinkButton"

function CompanyHero() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(
        relativePath: { eq: "mobile/company-hero-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "company-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div
      className="h-screen bg-white relative transition-all duration-75 overflow-hidden"
      style={{ maxHeight: 800 }}
    >
      <div className="absolute right-0 bottom-0 top-0 left-0 pointer-events-none flex items-end lg:items-center">
        <div className="w-11/12 max-w-md h-auto lg:hidden ml-auto">
          <GatsbyImage
            alt=""
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="w-full mx-auto max-w-screen-2xl hidden lg:block">
          <div className="w-1/2 h-auto ml-auto">
            <GatsbyImage
              alt=""
              image={data.desktopBackground.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </div>
      <div className="relative h-full flex flex-col">
        <div className="container flex-1 flex flex-col justify-center">
          <h1 className="text-4xl sm:text-4.5xl md:text-6xl leading-tight font-medium text-gray-900">
            NMC Soft
          </h1>
          <p className="max-w-3xl text-lg leading-snug md:text-2xl font-medium text-gray-700 pt-2">
            Is a software development company with a proven expertise in web,
            mobile applications, cloud computing and ecommerce applications
          </p>
          <div className="pt-6 pb-10">
            <PrimaryLinkButton
              to="/company/#work-with-us"
              title="meet us in person"
              size="large"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyHero
