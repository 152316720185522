import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function OurTeamSection() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/our-team.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "our-team.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="pt-16 lg:pt-24 flex flex-col">
      <div className="container mb-5 lg:mb-8">
        <h3 className="text-4xl md:text-5xl font-bold leading-tight text-gray-900">
          Our Team
        </h3>
        <p className="font-medium text-gray-800 text-xs lg:text-sm">
          Talent &#45; Dedication &#45; Experience
        </p>
      </div>
      <div className="w-full h-auto lg:hidden mb-8">
        <GatsbyImage
          alt=""
          sizes={{
            ...data.mobileBackground.childImageSharp.gatsbyImageData,
            aspectRatio: 1.875 / 1,
          }}
        />
      </div>
      <div className="flex container">
        <div className="hidden lg:block lg:w-1/2">
          <GatsbyImage
            alt=""
            image={data.desktopBackground.childImageSharp.gatsbyImageData}
            className="w-auto h-full"
            style={{ maxWidth: 640, marginRight: "auto" }}
          />
        </div>
        <div className="lg:w-1/2 lg:p-12 flex flex-col justify-center">
          <p className="font-medium text-lg lg:text-2xl mb-5">
            We’re a team of developers, engineers, ecommerce experts and more,
            working together to build software that powers your business.
          </p>
          <span className="text-gray-800 text-base lg:text-lg">
            We choose our staff based on 3 pillars{" "}
            <span className="text-blue-600 inline-block">talent</span>,{" "}
            <span className="text-blue-600 inline-block">dedication</span> and{" "}
            <span className="text-blue-600 inline-block">experience</span>.
            <span className="my-6 block">
              25 Tech Lovers, self-learners, talented youngsters in age but not
              in experience make up the team that is committed to our{" "}
              <span className="text-blue-600 font-bold">set of values</span>.
            </span>
            We apply Agile methodology and are flexible to work with any project
            management tools depending on the client.
          </span>
        </div>
      </div>
    </div>
  )
}

export default OurTeamSection
